<template>
  <div class="home">
    <div class="head">
      <Head ref="head"></Head>
    </div>
    <ScreenVideo video="https://xuanhe-web.oss-cn-beijing.aliyuncs.com/assets/media/main-video.mp4"
      img="https://xuanhe-web.oss-cn-beijing.aliyuncs.com/assets/media/main-video.jpg"
      title="驱动创新与效率，为企业量身定制人工智能应用解决方案"
      subtitle="深入理解细分行业的独特挑战和需求，开发定制化AI工具和企业级智控平台"></ScreenVideo>

    <div class="section-lr">
      <div class="section-tb flex-auto bg-color-2 color-1 frame-left" style="align-items: flex-start">
        <p>为行业伙伴提供独特的洞察和技术解决方案，优化现有业务流程，开辟新的增长路径。</p>
        <div style="margin-top: auto;">
          <a class="btn-sm" @click="showContactUs">联系我们</a>
        </div>
      </div>
      <div class="section-tb flex-auto bg-color-2 color-1 frame-right" style="align-items:flex-start">
        <p>帮助企业解锁新的商业价值，通过智能化的操作和决策，加速企业成长。</p>
        <div style="margin-top: auto;">
          <a class="btn-sm" @click="showContactUs">即刻尝试</a>
        </div>
      </div>
    </div>

    <div id="tyaicp" class="section-tb frame width-100pct">
      <div class="text-container-lr">
        <h1>通用AI产品</h1>
        <p>让您的企业提升<b>日常工作效率</b>。</p>
      </div>
      <div class="width-100pct margin-b-4">
        <el-tabs  v-model="tyAICpTabActiveName"
                  tab-position="top"  class="" ><!--height-100vh-head 配合下面的滚动事件，是否也可以不要-->
          <el-tab-pane v-for="(main,index) in tyAiCpList" :key="index"
                       :label="main.name" :name="main.id" >
            <span slot="label"><h2>{{main.name}}</h2></span>
            <div><!--class="height-100vh-head scroll-hidden" @scroll="handleScrollTyAiCp($event)"-->
              <div class="section-lr margin-b-1" style="">
                <div class="width-60pct"><!-- height-50vh 定义了高度 就不能和flex布局的align-items: stretch 就失效了-->
                  <img class="img-with-shadow" :src="main.img">
                </div>
                <div class="flex-auto margin-l-1">
                  <h2>{{main.title}}</h2>
                  <p class="para-md">{{main.content}}</p>
                </div>
              </div>
              <div class="section-lr gap-flex-1r">
                <div v-for="(item,indexItem) in main.items" :key="indexItem"
                     class="flex-auto" style="position:relative;max-height:33vh"><!--bg-color-1-->
                  <img class="img-with-shadow" style="position:absolute;opacity: .4"
                        :src="item.img"><!--opacity: .6-->
                  <div style="height: 100%; position: relative;padding: 0rem 0rem 0rem 0.5rem;box-sizing: border-box;"
                       class="z-index-10  " ><!--color-1-->
                    <h4>{{item.title}}</h4>
                    <p class="font-size-sm">{{item.content}}</p>
                  </div>
                </div>
              </div>
            </div>

          </el-tab-pane>
        </el-tabs>
      </div>
      <a class="btn-lg" @click="showContactUs">产品咨询</a>
    </div>

    <div id="rgznjs" class="section-tb frame width-100pct">
      <div class="text-container-lr">
        <h1>人工智能技术</h1>
        <p>视觉人工智能提升<b>内部管理效率</b>。</p>
      </div>
      <div class="section-lr margin-b-4 gap-flex-1r">
        <div class="flex-auto" style="position:relative"
             v-for="(item,index) in rgznjsList" :key="index">
          <div class="height-33vh width-100pct bg-color-1" >
            <img class="img-with-shadow" :src="item.img" style="">
          </div>
          <div
              class="width-100pct margin-t-1"
              style="">
            <h3 class="">{{item.title}}</h3>
            <p class="color-2">{{item.content}}</p>
          </div>
        </div>


      </div>
<!--      <a class="btn-lg" @click="showContactUs">联系方案专家</a>-->
    </div>

    <div id="hyjjfa" class="section-tb frame width-100pct">
      <div class="text-container-lr">
        <h1>行业解决方案</h1>
        <p>我们的解决方案平台提供最新的<b>行业模型</b>和专业<b>领域实践</b>指南。</p>
      </div>
      <div class="section-lr margin-b-4 gap-flex-1r">
        <div class="flex-auto margin-b-1" style="position:relative;min-width:30%"
             v-for="(item,index) in hyjjfaList" :key="index">
          <div class="height-33vh width-100pct " >
            <img class="img-with-shadow" :src="item.img" style="">
          </div>
          <div
              class="width-100pct margin-t-1"
              style="">
            <h3 class="">{{item.title}}</h3>
          </div>
        </div>

      </div>
      <a class="btn-lg" @click="showContactUs">解决方案咨询</a>
    </div>

<!--    <div class="section-2">
      <div class="rw-container">
        <a>
          <div class="show">
            <p>为行业伙伴提供独特的洞察和技术解决方案，优化现有业务流程，开辟新的增长路径。</p>
          </div>
          <div class="operate btn-sm">现在联系我们</div>
        </a>
        <a>
          <div class="show">
            <p>帮助企业解锁新的商业价值，通过智能化的操作和决策，加速企业成长。</p>
          </div>
          <div class="operate btn-sm">即刻尝试</div>
        </a>
      </div>
    </div>-->


<!--    <div class="section-5 frame">
      <div class="text-container">
        <div class="title-1">解决方案</div>
        <div class="title-2">多领域AI，深度行业垂直AI解决方案。</div>
      </div>
      <div class="menu-show-container">
        <div class="menu-sub">
          <a>虚拟训练场</a>
          <a>医疗图像模拟</a>
          <a>视频去除</a>
          <a>纹样生成</a>
          <a>员工AI助理</a>
          <a>AI反向鉴真</a>
        </div>
        <div class="show-sub">
          <div class="sub-1">
            <div>
              <h2>虚拟训练场</h2>
              <p>针对特定主题和风格训练您自己的自定义 AI 模型。</p>
            </div>
            <a>现在尝试</a>
          </div>
          <div class="sub-2">
            <video style="object-fit: cover;border-radius: 0.5rem;width: 100%;height: 100%;max-width: 100%" autoplay loop playsinline muted poster="https://d3phaj0sisr2ct.cloudfront.net/site/videos/mb-clouds-poster.png">
              <source src="https://d3phaj0sisr2ct.cloudfront.net/site/videos/mb-clouds.mp4" type="video/mp4">
            </video>
          </div>
          <a class="sub-3">更多智慧方案</a>
        </div>

      </div>
    </div>-->

    <div class="section-tb frame-bottom width-100pct bg-color-3" style="">
      <h1>平台能力</h1>
      <p>随时随地在线<b>共享和驾驭AI工具</b>。</p>
      <div class="grid-2r-2c" style="text-align: center"><!---->
        <div class="margin-t-3">
          <div style="float:left;" class="ptnl-item">
            <img style="" src="@/assets/images/index/ptnl-1.png">
            <p style="">市场前沿的AI底层能力。</p>
          </div>
        </div>
        <div class="margin-t-3">
          <div style="float:right;" class="ptnl-item">
            <img style="" src="@/assets/images/index/ptnl-2.png">
            <p style="">交互式、可配置AI工具。</p>
          </div>

        </div>
        <div class="margin-t-3">
          <div style="float:left;" class="ptnl-item">
            <img style="" src="@/assets/images/index/ptnl-3.png">
            <p style="">快速嵌入，适用于各行各业务。</p>
          </div>

        </div>
        <div class="margin-t-3">
          <div style="float:right;" class="ptnl-item">
            <img style="" src="@/assets/images/index/ptnl-4.png">
            <p style="">市场前言的AI底层能力。</p>
          </div>
        </div>


      </div>
    </div>
<!--    <div class="section-6">-->
<!--      <div class="title">-->
<!--        <h2>平台能力</h2>-->
<!--        <p>随时随地在线共享和驾驭AI 工具</p>-->
<!--      </div>-->
<!--      <div class="group">-->
<!--        <div>-->
<!--          <img src="">-->
<!--          <p>市场前沿的AI底层能力。</p>-->
<!--        </div>-->
<!--        <div>-->
<!--          <img src="">-->
<!--          <p>交互式、可配置AI工具。</p>-->
<!--        </div>-->
<!--        <div>-->
<!--          <img src="">-->
<!--          <p>快速嵌入，适用于各行各业务。</p>-->
<!--        </div>-->
<!--        <div>-->
<!--          <img src="">-->
<!--          <p>市场前言的AI底层能力。</p>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

    <div class="footer">
      <Footer></Footer>
    </div>

    <ContactUs ref="contactUs"></ContactUs>
  </div>
</template>

<script>
import Head from '@/components/Head.vue'
import Footer from '@/components/Footer.vue'
import ContactUs from "@/components/ContactUs.vue"
import ScreenVideo from '@/components/ScreenVideo.vue'

export default {
  name: 'Index',
  components: {
    Head,
    Footer,
    ContactUs,
    ScreenVideo
  },
  data() {
    return {
      dlgCtcUsVisible:false,
      tyAICpTabActiveName: "1",//激活的选项卡
      tyAiCpList:[
        {
          name:"虚拟训练场",
          id:"1",//用于激活选项卡使用
          img:require("@/assets/images/index/xnxlc-main-sm.jpg"),
          title:"虚拟训练场",
          content:"AI根据实际场景模拟出一个虚拟环境，可以用于教育、训练、研究等。在这样的环境中，人们可以通过与虚拟场景交互来学习新的技能或提高现有技能，而无需面对现实世界中的风险或后果。",
          items:[
            {
              img:require("@/assets/images/index/xnxlc-1-sm.jpg"),
              title:"教育",
              content:"通过模拟的实验室环境进行科学实验，或者通过虚拟历史场景增进对历史事件的理解。"
            },
            {
              img:require("@/assets/images/index/xnxlc-2-sm.jpg"),
              title:"军事",
              content:"用于战术训练和模拟战场环境，让士兵在没有实际风险的情况下练习操作和战术决策。"
            },
            {
              img:require("@/assets/images/index/xnxlc-3-sm.jpg"),
              title:"医疗",
              content:"医生和医学生可以在模拟的手术环境中练习手术技巧，或者通过虚拟患者进行诊断训练。"
            },
            {
              img:require("@/assets/images/index/xnxlc-4-sm.jpg"),
              title:"航空",
              content:"飞行员通过模拟飞行训练，提高飞行技能和应对紧急情况的能力。"
            },
            {
              img:require("@/assets/images/index/xnxlc-5-sm.jpg"),
              title:"企业培训",
              content:"模拟商业环境和情景，帮助员工学习商业策略、领导力和团队协作技巧。"
            }
          ]
        },
        {
          name:"图像模拟",
          id:"2",
          img:require("@/assets/images/index/txmn-main-sm.jpg"),
          title:"图像模拟",
          content:"AI图像模拟技术通过人工智能算法生成或改进图像，为视觉艺术、娱乐产业、科学研究及医疗诊断等多个领域提供了高度创新的应用解决方案，显著增强了这些领域的视觉表现力和分析能力。",
          items:[
            {
              img:require("@/assets/images/index/txmn-1-sm.jpg"),
              title:"医学成像和诊断",
              content:"AI技术能够增强和改进医学成像，如X光片、MRI和CT扫描，帮助医生更准确地诊断疾病。"
            },
            {
              img:require("@/assets/images/index/txmn-2-sm.jpg"),
              title:"历史重建和文化遗产",
              content:"通过AI图像模拟技术，可以重建历史遗址或已损坏的艺术作品的视觉形象，为文化遗产保护和研究提供有价值的资源。"
            },
          ]
        },
        {
          name:"内容生成",
          id:"3",
          img:require("@/assets/images/index/nrsc-main-sm.jpg"),
          title:"内容生成",
          content:"利用AI技术自动创建文本、图像、音频和视频等内容。这一技术领域涉及多种算法和模型，包括但不限于自然语言处理（NLP）、深度学习、生成对抗网络（GANs）和强化学习。AI内容生成的应用广泛，能够高效地产出高质量的内容，为不同行业和领域提供了创新的解决方案。",
          items:[
            {
              img:require("@/assets/images/index/nrsc-1-sm.jpg"),
              title:"文本生成",
              content:"包括自动撰写新闻文章、生成创意写作内容、自动编程代码、生成产品描述等。"
            },
            {
              img:require("@/assets/images/index/nrsc-2-sm.jpg"),
              title:"视频内容生成",
              content:"自动编辑视频，生成动画或模拟现实场景的视频内容。"
            },
            {
              img:require("@/assets/images/index/nrsc-3-sm.jpg"),
              title:"游戏内容生成",
              content:"自动化生成游戏元素，如地图、角色和任务。"
            },
          ]
        },
        {
          name:"智能助手",
          id:"4",
          img:require("@/assets/images/index/znzs-main-sm.jpg"),
          title:"智能助手",
          content:"AI智能助手在企业中的应用正变得越来越广泛，它们通过自动化和智能化的方式提高工作效率、优化客户服务、增强决策支持等，为企业带来显著的价值。",
          items:[
            {
              img:require("@/assets/images/index/znzs-1-sm.jpg"),
              title:"自动化客户查询响应",
              content:"AI智能助手可以24/7不间断地处理客户咨询，提供即时响应，减轻人工客服的负担。"
            },
            {
              img:require("@/assets/images/index/znzs-2-sm.jpg"),
              title:"个性化客户体验",
              content:"通过分析客户数据和行为，智能助手能够提供定制化的推荐和服务，增强客户满意度。"
            },

          ]
        },
        {
          name:"深度伪造检测",
          id:"5",
          img:require("@/assets/images/index/aifxjz-main-sm.jpg"),
          title:"深度伪造检测",
          content:"识别和鉴别通过人工智能技术生成或修改的音频、视频和图像内容。通过学习真实与伪造内容之间的细微差异，来识别不自然的模式，同时分析媒体文件的元数据和像素级的不一致性，查找编辑痕迹和压缩错误。以确保信息真实、安全，防止误导和滥用。",
          items:[
            {
              img:require("@/assets/images/index/aifxjz-1-sm.jpg"),
              title:"辨识假新闻和深度伪造内容",
              content:"验证新闻报道、图片或视频的真实性，帮助打击假新闻和误导性信息的传播。"
            },
            {
              img:require("@/assets/images/index/aifxjz-2-sm.jpg"),
              title:"识别和过滤AI生成内容",
              content:"监控和识别社交媒体平台上的AI生成帖子、评论或图片，减少虚假信息和滥用行为。"
            },
            {
              img:require("@/assets/images/index/aifxjz-3-sm.jpg"),
              title:"检测学术不端行为",
              content:"辨别学术作业、论文是否为AI生成，确保学术诚信。"
            },
            {
              img:require("@/assets/images/index/aifxjz-4-sm.jpg"),
              title:"确认艺术作品的原创性",
              content:"鉴定艺术作品是否为人工创作还是AI生成，保护艺术家的创作权益。"
            },


          ]
        },

      ],
      rgznjsList:[
        {
          img:require("@/assets/images/index/rgznjs-1-sm.jpg"),
          title:"病理学实例",
          content:"准确捕捉图像中的基本特征"
        },
        {
          img:require("@/assets/images/index/rgznjs-2-sm.jpg"),
          title:"养殖监测实例",
          content:"监测牧场动物状态"
        },
        {
          img:require("@/assets/images/index/rgznjs-3-sm.jpg"),
          title:"数码零部件实例",
          content:"更好的区分缺陷和零件特征"
        },
      ],
      hyjjfaList:[
        {
          img:require("@/assets/images/index/hyjjfa-1-sm.jpg"),
          title:"农业种植解决方案"
        },
        {
          img:require("@/assets/images/index/hyjjfa-2-sm.jpg"),
          title:"医疗教学解决方案"
        },
        {
          img:require("@/assets/images/index/hyjjfa-3-sm.jpg"),
          title:"自媒体传媒平台解决方案"
        },
        {
          img:require("@/assets/images/index/hyjjfa-4-sm.jpg"),
          title:"快消品解决方案"
        },
        {
          img:require("@/assets/images/index/hyjjfa-5-sm.jpg"),
          title:"畜牧业综合解决方案"
        },
        {
          img:require("@/assets/images/index/hyjjfa-6-sm.jpg"),
          title:"制造业质检解决方案"
        },
      ],
      pgnlList:[
        {
          img:""
        }
      ],

    }
  },
  methods:{
    handleScrollTyAiCp(event){
      const container = event.target;//document.querySelector('.tyAiCp'); // 获取包含滚动条的div元素

      console.log('container.clientHeight'+container.clientHeight);
      console.log('container.scrollTop'+container.scrollTop);
      console.log('container.scrollHeight'+container.scrollHeight);
      if (container.clientHeight + container.scrollTop >= container.scrollHeight) {
        console.log('已经滚动到底部！');

        for( var i in this.tyAiCpList){

          var item = this.tyAiCpList[i];
          if(item.id == this.tyAICpTabActiveName){
            i++;
            if(i<this.tyAiCpList.length){
              item = this.tyAiCpList[i];
              this.tyAICpTabActiveName=item.id ;
              //container.scrollTop = 0;
              break;
            }
          }
        }

        // 这里可以写入需要执行的代码逻辑
      }
    },
    showContactUs(){
      this.$refs.contactUs.show();
    },
  }
}
</script>

<style scoped lang="scss">
@import "~@/assets/styles/index.scss";

/*::v-deep .el-tabs__item{
  height: 60px;
}*/


::v-deep .el-tabs__nav{
  left: 50%;
  transform: translateX(-50%) !important;
}

::v-deep .el-tabs__content{
  overflow: visible;
}
</style>
