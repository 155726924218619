<template>
  <div>
    <el-dialog title="联系我们" :visible.sync="dlgCtcUsVisible"
               :lock-scroll="false"
               label-width="0px" width="450px">
      <el-form :model="form" :rules="rules" ref="form">
        <el-form-item label="" prop="contacts">
          <el-input v-model="form.contacts" autocomplete="off" placeholder="称呼"></el-input>
        </el-form-item>
        <el-form-item label="" prop="contactInfor">
          <el-input v-model="form.contactInfor" autocomplete="off" placeholder="联系方式"></el-input>
        </el-form-item>
        <el-form-item label="" prop="description">
          <el-input v-model="form.description" type="textarea" :rows="3"
              placeholder="描述您的问题">
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="">
        <el-button @click="dlgCtcUsVisible = false">取 消</el-button>
        <el-button type="primary" @click="onSubmit()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {clueInput} from '@/api/contactus/contactus'
import { Message } from 'element-ui'

export default{
  name: 'ContactUs',
  data() {
    return {
      dlgCtcUsVisible: false,
      form: {
        contacts: '',
        contactInfor: '',
        description: ''
      },
      rules: {
        contacts:[
            { required: true, message: '请输入您的称呼', trigger: 'blur' }
        ],
        contactInfor:[
          { required: true, message: '请输入您的联系方式', trigger: 'blur' }
        ],
        description:[
          { required: true, message: '请描述您的问题', trigger: 'blur' }
        ],

      }
    }
  },
  methods:{
    show(){
      this.dlgCtcUsVisible=true;
    },
    onSubmit(){
      this.$refs["form"].validate(valid => {
        if (valid) {
          clueInput(this.form).then(response => {
            this.dlgCtcUsVisible = false
            this.resetForm("form")
            console.log("接口调用成功后的处理逻辑");
            Message.success("提交成功");

          });
        }
      });


    }
  }
}
</script>