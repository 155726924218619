<template>
  <div class="section-bg">
    <!--
    浏览器限制有声音的视频即使添加自动播放属性autoplay也不能直接播放，添加muted意为静音播放即可实现自动播放。
    controls 属性 可以操作播放条和声音
    -->
    <video ref="videoPlayer" class="video-bg"
           autoplay loop playsinline muted
           @play="handlePlayVideoListener" @pause="handlePauseVideoListener"
           @loadedmetadata="onVideoMetadataLoaded"
           :poster="img"><!--@/assets/media/main-video.jpg-->
      <!--@/assets/media/main-video.mp4-->
      <source
          :src="video"
          type="video/mp4"
      />
    </video>
    <div class="section-tb color-1 z-index-10 frame-top text-align-center">
      <h1 class="">{{title}}</h1>
      <h3 class="margin-b-4 ">{{subtitle}}</h3>
      <!--        <a class="btn-lg">免费注册试用</a>-->
      <div class="btn" style="width: 100px;" @click="scrollDown">
        <img style="width:50px;height:50px" src="@/assets/images/icon/arrow-down.png">
      </div>
    </div>
    <div class="play-bg" style="width: 40px;height: 40px" v-show="isShowPlay" @click="onPlayOrPauseVideo">
      <img v-show="!isPlaying"  src="@/assets/images/icon/play-w.png" >
      <img v-show="isPlaying"  src="@/assets/images/icon/pause-w.png">
      <!--        <span style="font-size: 20px">播放</span>-->
    </div>
  </div>
</template>

<script>
export default{
  name: 'ScreenVideo',
  data(){
    return{
      isShowPlay:false,//是否显示播放操作
      isPlaying:true,//是否正在播放
    }
  },
  props:["video","img","title","subtitle"],
  /*props: {
    video: {
      type: String,
      default: 'require("@/assets/media/main-video.webm")'
    },
    img: {
      type: String,
      default: 'require("@/assets/media/main-video.jpg")'
    },
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
  },*/
  mounted() {
    // 在组件挂载后，设置视频元素的监听器
    //this.$refs.videoPlayer.addEventListener('loadedmetadata', this.onVideoMetadataLoaded);
  },
  beforeDestroy() {
    // 在组件销毁前，移除视频元素的监听器
    //this.$refs.videoPlayer.removeEventListener('loadedmetadata', this.onVideoMetadataLoaded);
  },
  methods:{
    onVideoMetadataLoaded() {
      // 视频元数据加载完成后的处理逻辑
      console.log('视频已加载完成！');
      this.isShowPlay=true;
      // 这里可以执行你需要的操作，比如显示加载完成的提示等
    },
    handlePauseVideoListener(){
      console.log('监听：视频已暂停');
      this.isPlaying=false;
    },
    handlePlayVideoListener(){
      console.log('监听：视频开始播放');
      this.isPlaying=true;
    },
    playVideo(video) {
      console.log("playVideo")
      video.play();
    },
    pauseVideo(video) {
      console.log("pauseVideo")
      video.pause();
    },
    onPlayOrPauseVideo(){
      console.log("onPlayOrPauseVideo")
      let video=this.$refs.videoPlayer;
      if(this.isPlaying){
        this.pauseVideo(video);
      }else{
        this.playVideo(video)
      }
    },
    scrollDown(){
      // 获取浏览器窗口的高度
      let windowHeight = window.innerHeight;
      // 打印窗口的高度
      //console.log('窗口高度: ' + windowHeight + 'px');
      let headHeight=0;
      if(this.$parent.$refs.head){
        const headElement = this.$parent.$refs.head.$el; // 获取子组件的DOM元素
        headHeight=headElement.offsetHeight
        //console.log(headElement.offsetHeight); // 输出元素的高度（包括padding、border）
      }


      //window.scrollTo(0, windowHeight-headElement.offsetHeight); // 向下滚动100像素
      // 使用window.scrollTo实现平滑滚动
      window.scrollTo({
        top: windowHeight-headHeight,
        behavior: 'smooth'
      });
    },
  }
}
</script>
<style scoped lang="scss">
@import "~@/assets/styles/index.scss";
</style>