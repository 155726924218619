import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import {
    Button,
    Checkbox,
    Col,
    Dialog,
    Form,
    FormItem,
    Input,
    Link,
    Message,
    Option,
    Select,
    Tabs,
    TabPane,
} from 'element-ui';

Vue.use(Button);
Vue.use(Checkbox);
Vue.use(Col);
Vue.use(Dialog);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Input);
Vue.use(Link);
Vue.use(Option);
Vue.use(Select);
Vue.use(Tabs);
Vue.use(TabPane);

//Vue.prototype.$message = Message;//全局加载 使用方式this.$messag


import '@/assets/styles/common.scss' // global css

import {resetForm} from "@/utils/form";

// 全局方法挂载
Vue.prototype.resetForm = resetForm



Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
