import axios from 'axios'

//请求头设置
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
//axios请求进行二次封装 创建一个新的 axios,也就是每个axios 都有自己的配置
//通过此方式可以创建多个axios进行分别配置
const service = axios.create({
    // axios中请求配置有baseURL选项，表示请求URL公共部分
    baseURL: process.env.VUE_APP_BASE_API,
    // 超时
    timeout: 10000*6
})

export default service