<template>
  <div class="head-container" :class="isScorll ? 'bgColor' : ''"
       @mouseenter="handleMouseEnter" @mouseleave="handleMouseleave">
    <div class="rw-container">
      <div class="logo" style="cursor:pointer">
        <img v-show="isScorll" src="@/assets/images/logo-bg.png" @click="goHome()">
        <img v-show="!isScorll" src="@/assets/images/logo.png" @click="goHome()">
<!--        <a >LOGO</a>-->
      </div>
      <ul class="menuList">
        <li v-for="(item,index) in tabList" :key="item.id" @click="tabClick(index,item.path)">
          <a >{{item.name}}</a>
        </li>
      </ul>
      <div class="login-signup">
<!--        <a class="login" @click="onLogin()">登录</a>
        <a class="signup btn-sm">免费注册</a>-->
      </div>
    </div>

  </div>
</template>

<script>
  export default {
    name: 'Head',
    data() {
      return {
        tabList: [
            /*{
              id: 1,
              path: 'home',
              name: '首页',
              hover: false
            },*/
          {
            path: '/',
            name: '首页',
          },
          {
            path:'product',
            name: '产品'
          },
            /*{
              path:'direction',
              name: '研究方向'
            },*/
            {
              path:'solution',
              name: '行业解决方案'
            },
            {
              path:'aitool',
              name: 'AI应用工具'
            },

            /*{
              id: 5,
              path: 'aboutUs',
              name: 'API对接',
              hover: false
            },*/
            {
              path:'aboutus',
              name: '关于玄合'
            },
        ], //导航列表
        isScorll: false,//是否有滚动条
      }
    },
    methods:{
      scrolling(){
        if (
            !!document.documentElement.scrollTop &&
            document.documentElement.scrollTop > 0 //window.innerHeight //
        ) {
          this.isScorll = true;

        } else {
          this.isScorll = false;
        }
      },
      handleMouseEnter(){
        this.isScorll = true;
      },
      handleMouseleave(){
        this.scrolling();
      },
      goHome(){
        this.$router.push({
          path: "/"
        })
      },
      tabClick(index, pathName){
        this.$router.push({
          path: pathName
        })
      },
      onLogin(){
        this.$router.push({
          path: "/login"
        })
      }
    },
    watch: {
      // 监听路由变化，如果路由变化了，则重置滚动位置
      $route: {
        handler: function (to, from) {
          this.$nextTick(function () {

            let isToHash=false;
            const hash = to.hash;
            console.log("===hash===",hash)
            if (hash) {
              const targetElement = document.getElementById(hash.substring(1));
              if (targetElement) {
                targetElement.scrollIntoView();
                isToHash=true;
              }
            }

            if(!isToHash){
              // 当路由更改时，将滚动位置重置为0
              window.scrollTo(0, 0)
            }


          });
        },
        immediate: true
      }
    },
    mounted() {
      window.addEventListener('scroll', this.scrolling);
    },
    beforeDestroy() {
      window.removeEventListener("scroll", this.scrolling);
    }
  }
</script>

<style scoped lang="scss">
  @import "~@/assets/styles/variables.scss";

  .bgColor{
    background-color: rgb(255,255,255,1);
    color:rgb(0,0,0,1) !important;
  }
  .head-container{
    color: $font-color-1;
    width: 100%;
    box-sizing: border-box;
    z-index: 1000;
    position: fixed;
    top:0;
    padding-left: 2.25rem;
    padding-right: 2.25rem;
    margin-left: auto;
    margin-right: auto;
    font-weight: 400;
    height: $home-head-height;

    .rw-container{
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 100%;

      .logo{
        display: flex;
        width: calc($left-right-space - 2.25rem);
        align-items: center;
        box-sizing: border-box;
      }

      .menuList {
        //min-width: 500px;
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
        margin: 0;
        //padding: 0;

        li {
          padding-top: 1rem;
          padding-bottom: 1rem;
          line-height: 1;
          //flex: 1 1;
          //text-align: center;
          padding-left: 2vw;//1.75rem;
          padding-right: 2vw;//1.75rem;
          text-align:center;
          a {
            font-size: 1.25rem;
            line-height: 1.5rem;
            //padding-left: 1.75rem;
            //padding-right: 1.75rem;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
            align-items: center;
          }
        }
      }

      .login-signup{
        align-items: center;
        //padding-top: 1rem;
        //padding-bottom: 1rem;
        width: calc($left-right-space - 2.25rem);//20240311
        display: flex;
        justify-content: space-between;
        box-sizing: border-box;

        a{
          line-height: 1.5rem;
        }
        .signup{
          /*font-size: .875rem;
          line-height: 1.25rem;*/
          //justify-content: center;
          //align-items: center;
          margin-left:0.25rem;//1rem;

        }
      }
    }

  }

</style>
