import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Index from '../views/index.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Index',
        component: Index
    },
    {
        path: '/direction',
        name: 'Direction',
        component: () => import(/* webpackChunkName: "about" */ '@/views/direction/index.vue')
    },
    {
        path: '/product',
        name: 'Product',
        component: () => import(/* webpackChunkName: "about" */ '@/views/product/index.vue')
    },
    {
        path: '/aitool',
        name: 'Aitool',
        component: () => import(/* webpackChunkName: "about" */ '@/views/aitool/index.vue')
    },
    {
        path: '/solution',
        name: 'Solution',
        component: () => import(/* webpackChunkName: "about" */ '@/views/solution/index.vue')
    },
    {
        path: '/aboutus',
        name: 'Aboutus',
        component: () => import(/* webpackChunkName: "about" */ '@/views/aboutus/index.vue')
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "about" */ '@/views/login/index.vue')
    },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
