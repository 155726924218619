<template>
  <div class="footer-container">
    <div class="rw-container">
      <div class="logo">
        <img src="@/assets/images/logo.png">
      </div>
      <div class="nav">
        <ul v-for="(tab,index) in tabList" :key="index">
          <li class="title">{{tab.title}}</li>
          <li v-for="(item,subindex) in tab.items" :key="subindex">
            <a :href="item.path+item.hash">{{ item.name }}</a>
<!--            <a @click="scrollToLocation(item.path,item.hash)">{{item.name}}</a>-->
          </li>
        </ul>
      </div>
    </div>
    <div class="copyright">
      <div class="content">
        <div class="company">© 2024 玄合科技有限公司</div>
        <div class="policy">
          <ul>
            <li><a>使用条款</a></li>
            <li><a>政策隐藏</a></li>
          </ul>
        </div>
        <div>
          <span style="white-space: nowrap;">京ICP备2023022419号-1</span>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'Footer',
  data(){
    return {
      tabList:[
        /*{
          title:"开始使用",
          items:[
            {name:"登录"},
            {name:"注册"}
          ]
        },*/
        {
          title:"首页",
          items:[
            {
              name:"通用AI产品",
              path:"/",
              hash:"#tyaicp"
            },
            {
              name:"人工智能技术",
              path:"/",
              hash:"#rgznjs"
            },
            {
              name:"行业解决方案",
              path:"/",
              hash:"#hyjjfa"
            },
          ]
        },
        {
          title:"产品",
          items:[
            {
              name:"AI智能派工",
              path:"/product",
              hash:"#aiznpg"
            },
            {
              name:"智能编辑助手",
              path:"/product",
              hash:"#znbjzs"
            },
            {
              name:"产品研究",
              path:"/product",
              hash:"#cpyj"
            },
          ]
        },
        {
          title:"行业解决方案",
          items:[
            {
              name:"农业",
              path:"/solution",
              hash:"#ny"
            },
            {
              name:"医疗健康",
              path:"/solution",
              hash:"#yljk"
            },
            {
              name:"文化",
              path:"/solution",
              hash:"#wh"
            },
            {
              name:"消费",
              path:"/solution",
              hash:"#xf"
            },
          ]
        },
        {
          title:"AI应用工具",
          items:[
            {
              name:"外贸电商",
              path:"/aitool",
              hash:"#wmds"
            },
            {
              name:"短视频",
              path:"/aitool",
              hash:"#dsp"
            },
            {
              name:"智能语音",
              path:"/aitool",
              hash:"#znyy"
            },
            {
              name:"智能视觉",
              path:"/aitool",
              hash:"#znsj"
            },
          ]
        },
        {
          title:"关于玄合",
          items:[
            {
              name:"我们的使命",
              path:"/aboutus",
              hash:"#sm"
            },
            {
              name:"企业愿景",
              path:"/aboutus",
              hash:"#yj"
            },
          ]
        }
      ]
    }
  },
  methods:{
    scrollToLocation(path,hash) {
      this.$router.push({ path: path, hash: hash })
    }
  },
  mounted() {

  },
}
</script>

<style scoped lang="scss">
@import "~@/assets/styles/variables.scss";

.footer-container{
  color: $font-color-1;
  padding-top: 6rem;
  background-color: rgb(17,17,17,1);
  position: relative;

  .rw-container{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-start;
    padding-left: 2.25rem;
    padding-right: 2.25rem;
    margin-left: auto;
    margin-right: auto;

    .logo{
      //width: 20%;
      width:calc($left-right-space - 2.25rem);
      justify-content: space-between;
      display: flex;
      margin-right: 1.5rem;
      //height: 48px;
    }
    .nav{
      display: flex;
      flex: 1 1;
      text-align:left;

      ul{
        line-height: 1.375rem;
        font-size: 1.125rem;
        flex: 1 1;
        margin: 0;
        //padding: 0;
        padding-left:2rem;

        .title{
          margin-bottom: 3rem;
        }

        li{
          line-height: 1.375rem;
          margin-bottom: 2rem;
          a{
            color: $font-color-2;

            &:hover{
              color: $font-color-hover-1;
            }
          }
        }

      }
    }
  }

  .copyright{
    background-color: rgb(118,31,227,1);
    .content{
      font-size: 1.125rem;
      line-height: 1.75rem;
      display: flex;
      justify-content: flex-end;
      position: relative;
      margin-top: 6rem;
      padding-left: 2.25rem;
      padding-right: 2.25rem;
      padding-top: 1rem;//1.75rem;
      padding-bottom: 1rem;//1.75rem;
      align-items: center;
      margin-left: auto;
      margin-right: auto;


      color: rgb(200,180,228,1);

      .company{
        //width: 20%;
        margin-bottom: 0;
        padding-right: 3rem;
      }
      .policy{
        justify-content: flex-start;
        flex-direction: row;
        //width: 80%;
        flex: 1 1;
        display: flex;

        ul{
          font-size: 1.125rem;
          line-height: 1.75rem;
          flex-direction: row;
          display: flex;
          color: rgb(200,180,228,1);

          li{
            margin-right: 3rem;

            a:hover{
              color: rgb(255,255,255,1);
            }
          }
        }
      }
    }
  }
}
</style>
